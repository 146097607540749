import { useState, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import { useSearchParams, createSearchParams } from 'react-router-dom';

import { SortOrder } from '@/store/utils/table/types';
import { FilterStatus, FilterType, ProgressRange } from './filter/types';
import { useDidUpdateEffect } from '@/hooks/useDidUpdateEffect';
import { AssignmentV3Status } from '@/store/assignments/typesOther';
import { DateRange } from '@/utils/time';
import { RLearningVisibility } from '@/store/v2';
import { LearningPartner } from '@/features/catalog/admin/constants/learning';

export const SEARCH_PARAM_KEYS = {
  SEARCH_PHRASE: 'searchPhrase',
  PAGE: 'page',
  SELECTED_STATUS: 'selectedStatus',
  SELECTED_CATEGORY: 'selectedCategory',
  SELECTED_SUBJECT: 'selectedSubject',
  SELECTED_VISIBILITY: 'selectedVisibility',
  SELECTED_ARCHIVED: 'selectedArchived',
  SELECTED_LEARNING_PARTNER: 'selectedLearningPartner',
  SELECTED_LEARNING_TYPE: 'selectedLearningType',
  SELECTED_YEAR: 'selectedYear',
  SELECTED_TEAM_IDS: 'selectedTeamIds',
  SORT_ORDER: 'sortOrder',
  SORT_BY: 'sortBy',
  SELECTED_PROGRESS_RANGE: 'selectedProgressRange',
  SELECTED_DATES_RANGE: 'selectedDatesRange',
  SELECTED_PUBLISHED_STATUS: 'selectedPublishedStatus',
  SELECTED_SPACE_ID: 'selectedSpaceId',
} as const;

/**
 *
 * @returns Filter values retrieved from search params
 */
const useFiltersFromRouterSearchParams = <TSortBy>(): {
  filtersFromSearchParams: {
    searchPhrase: string;
    page: number;
    selectedStatus: FilterStatus;
    selectedCategory: string;
    selectedArchived: boolean;
    selectedLearningPartner?: LearningPartner;
    selectedVisibility?: RLearningVisibility;
    selectedSubject: string;
    selectedLearningType: string;
    selectedYear?: number;
    selectedTeamIds: number[];
    sortOrder: SortOrder;
    sortBy?: TSortBy;
    selectedProgressRange?: ProgressRange;
    selectedDatesRange?: DateRange;
    selectedPublishedStatus?: AssignmentV3Status;
    selectedSpaceId?: number;
  };
  updateSearchParams: (filtersObject: Record<string, any>) => void;
} => {
  const [searchParams, setSearchParams] = useSearchParams();

  let defaultProgressRange: ProgressRange | undefined;
  try {
    defaultProgressRange = JSON.parse(searchParams.get(SEARCH_PARAM_KEYS.SELECTED_PROGRESS_RANGE) || '');
  } catch (e) {
    defaultProgressRange = undefined;
  }

  let selectedTeamIds = JSON.parse(searchParams.get(SEARCH_PARAM_KEYS.SELECTED_TEAM_IDS) || '[]');
  if (!Array.isArray(selectedTeamIds) || !selectedTeamIds.every((id) => Number.isInteger(id))) {
    selectedTeamIds = [];
  }

  let defaultDatesRange: DateRange | undefined;
  try {
    defaultDatesRange = JSON.parse(searchParams.get(SEARCH_PARAM_KEYS.SELECTED_DATES_RANGE) || '');
  } catch (e) {
    defaultDatesRange = undefined;
  }

  /**
   * Updates search params (stringifies values and applying it to url in format ?key=value)
   * @param filtersObject An object containing all filters to be used for updating search params
   */
  const updateSearchParams = (filtersObject: Record<string, any>) => {
    const notEmptyFiltersObj = (Object.keys(filtersObject) as Array<keyof typeof filtersObject>).reduce((acc, current) => {
      if (filtersObject[current]) {
        let value;

        // If a filter value is an object (e.g. Progress range)
        if (typeof filtersObject[current] === 'object') {
          try {
            // Then we try to stringify it to have it as router search param
            value = JSON.stringify(filtersObject[current]);
          } catch (e) {
            // If it's not possible to stringify it, let it just be undefined (it'll be omitted)
            value = undefined;
          }
        } // If a filter value is not an object
        else if (filtersObject[current]) {
          // We simply make it a string
          value = String(filtersObject[current]);
        }
        return {
          ...acc,
          [current]: value,
        };
      }
      return acc;
    }, {});

    // We want to keep the other search params that are not controlled by this filters
    const otherSearchParams = Object.fromEntries(
      Array.from(searchParams.entries()).filter(([key]) => !(Object.values(SEARCH_PARAM_KEYS) as string[]).includes(key))
    );

    const search = createSearchParams({ ...otherSearchParams, ...notEmptyFiltersObj });

    // We update search params with nav option of "replace" as true to preserve the history
    // instead of pushing a the same route with new params
    setSearchParams(search, { replace: true });
  };

  return {
    filtersFromSearchParams: {
      searchPhrase: searchParams.get(SEARCH_PARAM_KEYS.SEARCH_PHRASE) || '',
      page: Number(searchParams.get(SEARCH_PARAM_KEYS.PAGE)) || 0,
      selectedStatus: (searchParams.get(SEARCH_PARAM_KEYS.SELECTED_STATUS) as FilterStatus) || '',
      selectedCategory: searchParams.get(SEARCH_PARAM_KEYS.SELECTED_CATEGORY) || '',
      selectedSubject: searchParams.get(SEARCH_PARAM_KEYS.SELECTED_SUBJECT) || '',
      selectedLearningPartner: (searchParams.get(SEARCH_PARAM_KEYS.SELECTED_LEARNING_PARTNER) as LearningPartner) || undefined,
      selectedArchived: searchParams.get(SEARCH_PARAM_KEYS.SELECTED_ARCHIVED) === 'true' ? true : false,
      selectedVisibility: (searchParams.get(SEARCH_PARAM_KEYS.SELECTED_VISIBILITY) as RLearningVisibility) || undefined,
      selectedLearningType: searchParams.get(SEARCH_PARAM_KEYS.SELECTED_LEARNING_TYPE) || '',
      selectedYear: Number(searchParams.get(SEARCH_PARAM_KEYS.SELECTED_YEAR)) || undefined,
      selectedTeamIds,
      sortOrder: (searchParams.get(SEARCH_PARAM_KEYS.SORT_ORDER) as SortOrder) || 'asc',
      sortBy: (searchParams.get(SEARCH_PARAM_KEYS.SORT_BY) as unknown as TSortBy) || undefined,
      selectedProgressRange: defaultProgressRange,
      selectedDatesRange: defaultDatesRange,
      selectedPublishedStatus: (searchParams.get(SEARCH_PARAM_KEYS.SELECTED_PUBLISHED_STATUS) as AssignmentV3Status) || undefined,
      selectedSpaceId: Number(searchParams.get(SEARCH_PARAM_KEYS.SELECTED_SPACE_ID)) || undefined,
    },
    updateSearchParams,
  };
};

export const usePageState = <TSortBy>(
  defaultSortBy: TSortBy
): {
  canClearFilters: boolean;
  searchPhrase: string;
  setSearchPhrase: Dispatch<SetStateAction<string>>;
  selectedTeamIds: number[];
  setSelectedTeamIds: Dispatch<SetStateAction<number[]>>;
  selectedStatus?: FilterStatus;
  setSelectedStatus: Dispatch<SetStateAction<FilterStatus>>;
  selectedCategory?: string;
  setSelectedCategory: Dispatch<SetStateAction<string | undefined>>;
  selectedSubject?: string;
  setSelectedSubject: Dispatch<SetStateAction<string | undefined>>;
  selectedArchived?: boolean;
  setSelectedArchived: Dispatch<SetStateAction<boolean | undefined>>;
  selectedVisibility?: RLearningVisibility;
  setSelectedVisibility: Dispatch<SetStateAction<RLearningVisibility | undefined>>;
  selectedLearningType?: string;
  setSelectedLearningType: Dispatch<SetStateAction<string | undefined>>;
  selectedProgressRange?: ProgressRange;
  setSelectedProgressRange: Dispatch<SetStateAction<ProgressRange | undefined>>;
  sortBy: TSortBy;
  sortOrder: SortOrder;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  onSortChange: (sortBy: TSortBy, sortOrder: SortOrder) => void;
  onClearFilterState: (filter: FilterType) => void;
  selectedYear?: number;
  setSelectedYear: Dispatch<SetStateAction<number | undefined>>;
  selectedPublishedStatus?: AssignmentV3Status;
  setSelectedPublishedStatus?: Dispatch<SetStateAction<AssignmentV3Status | undefined>>;
  selectedDatesRange?: DateRange;
  setSelectedDatesRange: Dispatch<SetStateAction<DateRange | undefined>>;
  selectedSpaceId?: number;
  setSelectedSpaceId: Dispatch<SetStateAction<number | undefined>>;
  selectedLearningPartner?: LearningPartner;
  setSelectedLearningPartner: Dispatch<SetStateAction<LearningPartner | undefined>>;
} => {
  const { filtersFromSearchParams, updateSearchParams } = useFiltersFromRouterSearchParams<TSortBy>();

  // pagination
  const [sortBy, setSortBy] = useState<TSortBy>(filtersFromSearchParams.sortBy || defaultSortBy);
  const [sortOrder, setSortOrder] = useState<SortOrder>(filtersFromSearchParams.sortOrder);
  const [page, setPage] = useState(filtersFromSearchParams.page);
  // filters
  const [searchPhrase, setSearchPhrase] = useState<string>(filtersFromSearchParams.searchPhrase);
  const [selectedTeamIds, setSelectedTeamIds] = useState<number[]>(filtersFromSearchParams.selectedTeamIds);
  const [selectedStatus, setSelectedStatus] = useState<FilterStatus>(filtersFromSearchParams.selectedStatus);
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(filtersFromSearchParams.selectedCategory);
  const [selectedSubject, setSelectedSubject] = useState<string | undefined>(filtersFromSearchParams.selectedSubject);
  const [selectedLearningPartner, setSelectedLearningPartner] = useState<LearningPartner | undefined>(
    filtersFromSearchParams.selectedLearningPartner
  );
  const [selectedVisibility, setSelectedVisibility] = useState<RLearningVisibility | undefined>(
    filtersFromSearchParams.selectedVisibility
  );
  const [selectedLearningType, setSelectedLearningType] = useState<string | undefined>(
    filtersFromSearchParams.selectedLearningType
  );
  const [selectedArchived, setSelectedArchived] = useState<boolean | undefined>(filtersFromSearchParams.selectedArchived);
  const [selectedProgressRange, setSelectedProgressRange] = useState<ProgressRange | undefined>(
    filtersFromSearchParams.selectedProgressRange
  );
  const [selectedYear, setSelectedYear] = useState<number | undefined>(filtersFromSearchParams.selectedYear);
  const [canClearFilters, setCanClearFilters] = useState<boolean>(false);
  const [selectedDatesRange, setSelectedDatesRange] = useState<DateRange | undefined>(filtersFromSearchParams.selectedDatesRange);
  const [selectedPublishedStatus, setSelectedPublishedStatus] = useState<AssignmentV3Status | undefined>(
    filtersFromSearchParams.selectedPublishedStatus
  );
  const [selectedSpaceId, setSelectedSpaceId] = useState<number | undefined>(filtersFromSearchParams.selectedSpaceId);

  const filterValues = [
    selectedStatus,
    selectedCategory,
    selectedSubject,
    selectedVisibility,
    selectedArchived,
    selectedLearningType,
    selectedYear,
    selectedTeamIds,
    selectedProgressRange,
    searchPhrase,
    selectedPublishedStatus,
    selectedDatesRange,
    selectedSpaceId,
    selectedLearningPartner,
  ];

  // We use did update, since we don't want this effect to run on initial rendering
  useDidUpdateEffect(() => {
    updateSearchParams({
      selectedStatus,
      selectedCategory,
      selectedVisibility,
      selectedSubject,
      selectedArchived,
      selectedLearningType,
      selectedLearningPartner,
      selectedYear,
      selectedTeamIds,
      selectedProgressRange,
      searchPhrase,
      page,
      sortBy,
      sortOrder,
      selectedPublishedStatus,
      selectedDatesRange,
      selectedSpaceId,
    });
  }, [...filterValues, searchPhrase, page, sortBy, sortOrder]);

  // We use did update, since we don't want this effect to run on initial rendering
  useDidUpdateEffect(() => {
    setPage(0);
  }, [searchPhrase, ...filterValues]);

  useEffect(() => {
    const someFilterSelected = [...filterValues].some((filterValue) => {
      if (Array.isArray(filterValue)) {
        return Boolean(filterValue.length);
      }
      return Boolean(filterValue);
    });
    setCanClearFilters(someFilterSelected);
  }, [...filterValues]);

  const onSortChange = useCallback((sortBy: TSortBy, sortOrder: SortOrder) => {
    setSortBy(sortBy);
    setSortOrder(sortOrder);
  }, []);

  const handleClearFilterTypeState = (filter: FilterType) => {
    switch (filter) {
      case 'category':
        clearCategoryFilter();
        break;
      case 'subject':
        clearSubjectFilter();
        break;
      case 'visibility':
        clearVisibilityFilter();
        break;
      case 'archived':
        clearArchived();
        break;
      case 'learning type':
        clearLearningTypeFilter();
        break;
      case 'learningPartner':
        clearLearningPartnerFilter();
        break;
      case 'progress':
        clearProgressFilter();
        break;
      case 'status':
        clearStatusFilter();
        break;
      case 'team':
        clearTeamFilter();
        break;
      case 'year':
        clearYearFilter();
        break;
      case 'datesRange':
        clearDatesRangeFilter();
        break;
      case 'published status':
        clearPublishedStatusFilter();
        break;
      case 'spaceId':
        clearSpaceId();
        break;
      case 'clear all':
      default: {
        clearStatusFilter();
        clearCategoryFilter();
        clearSubjectFilter();
        clearVisibilityFilter();
        clearLearningTypeFilter();
        clearTeamFilter();
        clearYearFilter();
        setSearchPhrase('');
        clearProgressFilter();
        clearPublishedStatusFilter();
        clearDatesRangeFilter();
        break;
      }
    }
  };

  const clearTeamFilter = () => {
    setSelectedTeamIds([]);
  };

  const clearStatusFilter = () => {
    setSelectedStatus('');
  };

  const clearLearningTypeFilter = () => {
    setSelectedLearningType('');
  };

  const clearYearFilter = () => {
    setSelectedYear(undefined);
  };

  const clearLearningPartnerFilter = () => {
    setSelectedLearningPartner(undefined);
  };

  const clearProgressFilter = () => {
    setSelectedProgressRange(undefined);
  };

  const clearCategoryFilter = () => {
    setSelectedCategory('');
  };

  const clearSubjectFilter = () => {
    setSelectedSubject('');
  };

  const clearArchived = () => {
    setSelectedArchived(undefined);
  };

  const clearVisibilityFilter = () => {
    setSelectedVisibility(undefined);
  };

  const clearDatesRangeFilter = () => {
    setSelectedDatesRange(undefined);
  };

  const clearPublishedStatusFilter = () => {
    setSelectedPublishedStatus(undefined);
  };

  const clearSpaceId = () => {
    setSelectedSpaceId(undefined);
  };

  return {
    canClearFilters,
    searchPhrase,
    setSearchPhrase,
    selectedTeamIds,
    setSelectedTeamIds,
    selectedStatus,
    setSelectedStatus,
    selectedCategory,
    setSelectedCategory,
    selectedArchived,
    setSelectedArchived,
    selectedSubject,
    setSelectedSubject,
    selectedVisibility,
    setSelectedVisibility,
    selectedLearningType,
    setSelectedLearningType,
    selectedProgressRange,
    setSelectedProgressRange,
    sortBy,
    sortOrder,
    page,
    setPage,
    onSortChange,
    onClearFilterState: handleClearFilterTypeState,
    selectedYear,
    setSelectedYear,
    selectedPublishedStatus,
    setSelectedPublishedStatus,
    selectedDatesRange,
    setSelectedDatesRange,
    selectedSpaceId,
    setSelectedSpaceId,
    selectedLearningPartner,
    setSelectedLearningPartner,
  };
};

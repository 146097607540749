import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { useLearningContentInsightsNavigateWithContext } from '@/features/contentInsights/hooks/useLearningContentNavigate';
import { LearningItemType } from '@/features/journey/admin/hooks/useLazyLearningItems';
import { Icon } from '@/ui/Icon';
import { LearningPartner, PARTNER_ICON_MAP } from '@/features/catalog/admin/constants/learning';

const RootStyled = styled.div`
  display: flex;
  gap: 28px;
  align-items: center;
`;

const ContentTitle = styled(NavLink)`
  ${(p) => p.theme.typography.body3};
  white-space: normal;
  color: ${(p) => p.theme.tones.primary[50]};
`;

const PartnerIconStyled = styled(Icon)`
  color: ${(p) => p.theme.colors.neutral.onBackground};
  width: 24px;
  height: 24px;
`;

type JourneyInsightsLearningItemTitleCellProps = {
  row: LearningItemType;
};

export const JourneyInsightsLearningItemTitleCell = ({
  row: { title, urn, partner },
}: JourneyInsightsLearningItemTitleCellProps): JSX.Element => {
  const [, href] = useLearningContentInsightsNavigateWithContext(urn);

  const partnerIconKey = PARTNER_ICON_MAP[partner as LearningPartner];

  return (
    <RootStyled>
      {partnerIconKey ? <PartnerIconStyled icon={partnerIconKey} /> : null}
      <ContentTitle to={href}>{title || 'Untitled'}</ContentTitle>
    </RootStyled>
  );
};

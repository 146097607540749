import { useEffect } from 'react';
import styled from 'styled-components';
import { useRef } from 'react';

import { UnderlinedTabs } from '@/ui/Tabs';
import { JourneyInsightsTabsParticipantsContent } from '@/features/journey/admin/organisms/JourneyInsightsTabsParticipantsContent';
import { JourneyInsightsTabsLearningItems } from '@/features/journey/admin/organisms/JourneyInsightsTabsLearningItems';
import { useQueryState, useQueryBooleanState } from '@/hooks/useQueryState';

export enum QueryParams {
  Tab = 'tab',
  TabScroll = 'tabScroll',
}

enum Tab {
  LearningItems,
  Participants,
}

export enum TabQueryParam {
  LearningItems = 'learning-items',
  Participants = 'participants',
}

const TAB_QUERY_PARAM_TO_TAB: Record<TabQueryParam, Tab> = {
  [TabQueryParam.LearningItems]: Tab.LearningItems,
  [TabQueryParam.Participants]: Tab.Participants,
} as const;

export const TabContentContainer = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FiltersContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const HeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const TabsWrapperStyled = styled.div`
  width: 100%;
`;

export const JourneyInsightsTabs = (): React.ReactElement => {
  const [activeQueryParamTab, setActiveQueryParamTab] = useQueryState(QueryParams.Tab, TabQueryParam.LearningItems);
  const [activeQueryParamTabScroll, setActiveQueryParamTabScroll] = useQueryBooleanState(QueryParams.TabScroll, false);
  const tabsRef = useRef<HTMLDivElement>(null);

  // scroll to the tab when the tabScroll query param is set
  useEffect(() => {
    if (activeQueryParamTabScroll) {
      tabsRef.current?.scrollIntoView?.();
      // reset the tabScroll query param
      setActiveQueryParamTabScroll(undefined);
    }
  }, []);

  const handleTabChange = (tab: Tab) => {
    if (tab === Tab.Participants) {
      setActiveQueryParamTab(TabQueryParam.Participants, { resetParams: true });

      return;
    }

    setActiveQueryParamTab(TabQueryParam.LearningItems, { resetParams: true });
  };

  return (
    <TabsWrapperStyled ref={tabsRef}>
      <UnderlinedTabs
        activeTab={TAB_QUERY_PARAM_TO_TAB[activeQueryParamTab as TabQueryParam] || Tab.LearningItems}
        onActiveTabChange={handleTabChange}
      >
        <UnderlinedTabs.TriggersList>
          <UnderlinedTabs.Trigger label="Items" value={Tab.LearningItems} />
          <UnderlinedTabs.Trigger label="Learners" value={Tab.Participants} />
        </UnderlinedTabs.TriggersList>
        <UnderlinedTabs.ContentsList>
          <UnderlinedTabs.Content value={Tab.LearningItems}>
            <JourneyInsightsTabsLearningItems />
          </UnderlinedTabs.Content>
          <UnderlinedTabs.Content value={Tab.Participants}>
            <JourneyInsightsTabsParticipantsContent />
          </UnderlinedTabs.Content>
        </UnderlinedTabs.ContentsList>
      </UnderlinedTabs>
    </TabsWrapperStyled>
  );
};

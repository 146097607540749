import { useTranslation } from 'react-i18next';

import { ColumnDeclaration } from '@/ui/AutoTable';
import { LearnerActions, LearnerInfo, LearnerStatus } from '@/features/journey/admin/atoms/JourneyLearnerTableFormatter';
import { JourneyLearner } from '@/features/journey/admin/types/journeyLearner';
import { SortyBy } from '@/features/journey/admin/hooks/useJourneyLerners';

export type LearnerRow = JourneyLearner & { onRemove?: (ops: { id: number; name: string }) => void };

export const useLearnersColumnDeclaration = (): ColumnDeclaration<LearnerRow, SortyBy> => {
  const { t } = useTranslation('journey');

  return [
    {
      header: t('edit-learners-table-key-name'),
      component: LearnerInfo,
      sortKey: 'name',
    },
    {
      header: t('edit-learners-table-key-start-date'),
      dataKey: 'startedAt',
      cellFormatter: 'date',
      align: 'right',
      sortKey: 'startedAt',
    },
    {
      header: 'Progress',
      dataKey: 'progress',
      cellFormatter: 'progress',
      align: 'right',
      sortKey: 'progress',
    },
    {
      header: t('edit-learners-table-key-status'),
      component: LearnerStatus,
      sortKey: 'status',
    },
    {
      header: 'Last Activity',
      dataKey: 'lastActivityAt',
      cellFormatter: 'dateToNow',
      align: 'left',
      sortKey: 'lastActivityAt',
    },
    {
      component: LearnerActions,
      align: 'right',
    },
  ];
};

import { useSearchParams, useLocation } from 'react-router-dom';

type StateValueType = string | undefined;

type SetValueOptions = {
  resetParams?: boolean;
};

type ValueSerializeer<T> = {
  parse: (value: string) => T;
  serialize: (value: T) => string;
};

const defaultSerializeer: ValueSerializeer<StateValueType> = {
  parse: (value: string) => value,
  serialize: (value: StateValueType) => (value ? String(value) : ''),
};

export function useQueryState<T = StateValueType>(
  key: string,
  initialValue: T,
  serialize: ValueSerializeer<T> = defaultSerializeer as unknown as ValueSerializeer<T>
): [T, (value: T, options?: SetValueOptions) => void, (value: T) => string] {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const setValue = (value: T, { resetParams }: SetValueOptions = {}) => {
    const nextParams = resetParams ? new URLSearchParams() : searchParams;

    if (value) nextParams.set(key, serialize.serialize(value));
    else nextParams.delete(key);

    setSearchParams(nextParams, { replace: true });
  };

  const getLocationSearch = (val: T) => {
    const params = new URLSearchParams(location.search);

    if (val) params.set(key, serialize.serialize(val));
    else params.delete(key);

    return params.toString();
  };

  return [searchParams.get(key) ? serialize.parse(searchParams.get(key) as string) : initialValue, setValue, getLocationSearch];
}

type QueryNumberValue = number | undefined;

/**
 * Use this hook to manage a query parameter that can only take numeric values.
 * @param key The key of the query parameter.
 * @param initialValue The initial value of the query parameter.
 * @returns A tuple containing the current value of the query parameter, a function to set the value, and a function to get the value as a string.
 */
export function useQueryNumberState(
  key: string,
  initialValue?: QueryNumberValue
): [QueryNumberValue, (value: QueryNumberValue, options?: SetValueOptions) => void, (value: QueryNumberValue) => string] {
  return useQueryState<QueryNumberValue>(key, initialValue, {
    parse: (value: string) => (value && !isNaN(parseInt(value)) ? parseInt(value) : undefined),
    serialize: (value: QueryNumberValue) => (value ? String(value) : ''),
  });
}

type QueryBooleanValue = boolean | undefined;

/**
 * Use this hook to manage a query parameter that can only take boolean values.
 * @param key The key of the query parameter.
 * @param initialValue The initial value of the query parameter.
 * @returns A tuple containing the current value of the query parameter, a function to set the value, and a function to get the value as a string.
 */
export function useQueryBooleanState(
  key: string,
  initialValue?: QueryBooleanValue
): [QueryBooleanValue, (value: QueryBooleanValue, options?: SetValueOptions) => void, (value: QueryBooleanValue) => string] {
  return useQueryState<QueryBooleanValue>(key, initialValue, {
    parse: (value: string) =>
      ['true', 'false'].includes(String(value).toLowerCase()) ? String(value).toLowerCase() === 'true' : undefined,
    serialize: (value: QueryBooleanValue) => (typeof value === 'boolean' ? String(value) : ''),
  });
}

type QueryNumberArrayValue = number[] | undefined;
/**
 * Use this hook to manage a query parameter that can only take an array of numbers.
 * @param key The key of the query parameter.
 * @param initialValue The initial value of the query parameter.
 * @returns A tuple containing the current value of the query parameter, a function to set the value, and a function to get the value as a string.
 */
export function useQueryNumberArrayState(
  key: string,
  initialValue?: QueryNumberArrayValue
): [
  QueryNumberArrayValue,
  (value: QueryNumberArrayValue, options?: SetValueOptions) => void,
  (value: QueryNumberArrayValue) => string
] {
  return useQueryState<QueryNumberArrayValue>(key, initialValue, {
    parse: (value: string) => {
      const values = JSON.parse(value);
      return Array.isArray(values) ? values.map(Number).filter((v) => !isNaN(v)) : undefined;
    },
    serialize: (value: QueryNumberArrayValue) => (value ? JSON.stringify(value) : ''),
  });
}

/**
 * Use this hook to manage a query parameter that can only take a limited set of values.
 * @param key The key of the query parameter.
 * @param enumValues The set of values that the query parameter can take.
 * @param initialValue The initial value of the query parameter.
 * @returns A tuple containing the current value of the query parameter, a function to set the value, and a function to get the value as a string.
 */
export function useQueryEnumState<T extends string | undefined>(
  key: string,
  enumValues: T[],
  initialValue?: T
): [T | undefined, (value: T | undefined, options?: SetValueOptions) => void, (value: T | undefined) => string] {
  return useQueryState<T | undefined>(key, initialValue || undefined, {
    parse: (value: string) => (value && enumValues.includes(value as T) ? (value as T) : undefined),
    serialize: (value: T | undefined) => (value ? String(value) : ''),
  });
}
